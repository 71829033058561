import { useNavigation, useResource } from "@pankod/refine-core";
import { config } from "config";
import React, { createContext, PropsWithChildren, useContext } from "react";
import logo from "assets/img/logologo.png";
import banner from "assets/img/banner-may15.png";
import { useMe } from "./MeContext";

enum BannerPosition {
  TopLeft = "top-left",
  TopRight = "top-right",
  TopCenter = "top-center",
  BottomLeft = "bottom-left",
  BottomRight = "bottom-right",
  BottomCenter = "bottom-center",
  CenterLeft = "center-left",
  CenterRight = "center-right",
  CenterCenter = "center-center",
}

export interface BrandInfo {
  color_primary: string;
  logo_url: string;
  border_radius?: boolean;
  background_color: string;
  navbar_fontsize: number;
  navbar_color: string;
  logo: string;
}

export interface FooterInfo {
  background_url: string;
  background_color: string;
  copyright_bg: string;
  responsiveFooter: string;
}

export interface ContactInfo {
  title: string;
  address: string;
  website: string;
  phone: string;
  email: string;
  mh_title: string;
  company_color: string;
  show_mh: boolean;
}

export interface BannerInfo {
  banner_url: string;
  description: string;
  position: BannerPosition;
  horizontal?: string;
  vertical?: string;
  banner?: string;
  responsiveBanner: string;
}

interface BannerConfig {
  horizontal?: string;
  vertical?: string;
  banner?: string;
}

export interface HRAInfo {
  employer_name: string;
  can_skip_hra?: boolean;
  image1: string;
  image2: string;
}

export interface EmployerInfo {
  name: string;
  color: string;
  font_size: number;
  tagline?: string;
  tagline_color?: string;
  tagline_font_size?: number;
  btn_contact_us_color: string;
  not_allow_register_by_email_list: boolean;
}

export interface HRASummaryInfo {
  title_color: string;
  chart_color_scheme: string;
  main_hra_color: string;
  background_color: string;
  uncomplete_button_color: string;
  company_chart_status: boolean;
  default_chart_threshold: boolean;
  user_threshold: boolean;
}

export interface RiskAreasInfo {
  image_url: string;
  title_color: string;
  chart_color_scheme: string;
  title_color2: string;
}

export interface ActionPlanInfo {
  title_color: string;
  border_color: string;
  background_color: string;
  btn_add_activity_background_color?: string;
  find_your_activities_background_color?: string;
  level_color: string;
}

export interface LeaderboardInfo {
  level_color: string;
  other_texts_color: string;
  image_url: string;
  top_users: number;
}

export interface HomepageInfo {
  challenges_icon: string;
  activities_icon: string;
  resources_icon: string;
  hra_icon: string;
  contact_icon: string;
  home_bg: string;
  home_title: string;
  home_description: string;
  nav_blur: boolean;
  chat_icon: string;
}

interface CompanyContextProps {
  banner_config: BannerConfig;
  banner_info: BannerInfo;
  contact: ContactInfo;
  brand: BrandInfo;
  footer: FooterInfo;
  hra: HRAInfo;
  employer: EmployerInfo;
  hra_summary: HRASummaryInfo;
  risk_areas: RiskAreasInfo;
  action_plan: ActionPlanInfo;
  leaderboard: LeaderboardInfo;
  name: string;
  path: string;
  id: string;
  code: string;
  challenge: ChallengeInfo;
  homepage: HomepageInfo;
}

export interface ChallengeInfo {
  month: string;
  season: string;
  banner_image: string;
  text_color1: string;
  text_color2: string;
}

interface _CompanyContextProps {}

const CompanyContext = createContext<CompanyContextProps>({} as CompanyContextProps);

export const useCompany = () => {
  return useContext(CompanyContext);
};

export const CompanyProvider: React.FC<PropsWithChildren<_CompanyContextProps>> = ({ children, ...props }) => {
  const { push } = useNavigation();
  const { resourceName, resource } = useResource();
  const { me, myHRA, isSuccess, company } = useMe();
  const portfolio = company?.portfolio;
  const position = portfolio?.banner?.position?.split("-");
  const horizontal = position?.[1] as any;
  const vertical = position?.[0] as any;
  const _logo = portfolio?.brand?.logo ? config.ASSET_URL + portfolio?.brand?.logo : logo;
  const _banner = portfolio?.banner?.banner_url ? config.ASSET_URL + portfolio?.banner?.banner_url : banner;
  React.useEffect(() => {
    if (resourceName === "admin" || resource.options?.route?.includes("admin/") || portfolio?.hra?.can_skip_hra) return;
    if (me && isSuccess && !myHRA && company?.path) {
      push(`/${company?.path}/hra`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, resourceName, myHRA, isSuccess]);

  const value = React.useMemo(() => {
    return {
      ...portfolio,
      banner: {
        ...portfolio?.banner,
        banner_url: _banner,
        horizontal,
        vertical,
      },
      brand: {
        ...portfolio?.brand,
        logo: _logo,
      },
      name: company?.name,
      path: company?.path,
      id: company?.id,
      code: company?.code,
    };
  }, [_banner, _logo, company?.code, company?.id, company?.name, company?.path, horizontal, portfolio, vertical]);

  return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>;
};

import React, { useState, useEffect } from "react";
import { NotificationOutlined, SettingOutlined, UserAddOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import ProfileNew from "./Profile-new";
import Notifications from "./Notifications";
import EmployeeDetails from "./EmployeeDetails";
import { css } from "@emotion/react";
import "./account.scoped.scss";

type MenuItem = Required<MenuProps>["items"][number];
const MyAccount: React.FC = () => {
  const [current, setCurrent] = useState("profile");
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
  };
  const [menuMode, setMenuMode] = useState<"vertical" | "horizontal">("vertical");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(window.innerWidth <= 768);
        setMenuMode("horizontal");
      } else {
        setMenuMode("vertical");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="settings-page pageload j-between"
      css={css`
        padding: 0px 30px;
        .ant-menu .ant-menu-item-selected {
          background-color: #f2f4f9 !important;
          font-weight: 450 !important;
          color: #333 !important;
        }
        .ant-menu.ant-menu-root.ant-menu-vertical {
          border-inline-end: none;
        }
        .vt {
          border-left: 1px solid rgba(124, 124, 124, 0.2);
          margin: -30px 0 -30px 0 !important;
          height: 100vh;
        }
        h3 {
          margin: 30px 0 30px 10px;
        }
        @media (max-width: 768px) {
          padding: 0px 0px;
          .icon {
            display: none !important;
          }
        }
        h3 {
          @media (max-width: 768px) {
            margin: 20px 0 10px 20px !important;
          }
        }
      `}
    >
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode={menuMode}
        style={{
          width: isMobile ? "100%" : 256,
          textAlign: isMobile ? "center" : "left",
          minHeight: "100% !important",
          paddingRight: "20px",
          //overflowY: 'scroll',
          display: "inline-block",
        }}
      >
        <h3 style={{ textAlign: "left" }}>Settings</h3>
        <Menu.Item key="profile" icon={<SettingOutlined className="icon" />}>
          Profile
        </Menu.Item>
        <Menu.Item key="notifications" icon={<NotificationOutlined className="icon" />}>
          Notifications
        </Menu.Item>
        <Menu.Item key="employeedetails" icon={<UserAddOutlined className="icon" />}>
          Employee Details
        </Menu.Item>
      </Menu>
      <div className="vt"></div>
      <div
        css={css`
          background-color: white;
          flex: 1;
          padding: 0px 50px;
          margin-top: 30px;
          @media only screen and (max-width: 768px) {
            padding: 10px 10px 50px 10px !important;
            margin-top: 10px !important;
          }
        `}
      >
        {current === "profile" && <ProfileNew />}
        {current === "notifications" && <Notifications />}
        {current === "employeedetails" && <EmployeeDetails />}
      </div>
    </div>
  );
};

export default MyAccount;

import React, { PureComponent } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  ReferenceLine,
  Scatter,
  LabelList
} from 'recharts';
import { useList, useTable } from "@pankod/refine-core";
import { useMe } from "context/MeContext";
// const data = [
//   {
//     name: 'EMOTIONAL',
//     maxscore: 2,
//     minscore: -2.3,
//     medianscore: 0.5,
//   },
//   {
//     name: 'ENVIRONMENTAL',
//     maxscore: 2,
//     minscore: -2.3,
//     medianscore: 0.5,
//   },
//   {
//     name: 'FINANCIAL',
//     maxscore: 2,
//     minscore: -2.3,
//     medianscore: 0.5,
//   },
//   {
//     name: 'INTELLECTUAL',
//     maxscore: 2,
//     minscore: -2.3,
//     medianscore: 0.5,
//   },
//   {
//     name: 'OCCUPATIONAL',
//     maxscore: 2,
//     minscore: -2.3,
//     medianscore: 0.5,
//   },
//   {
//     name: 'PHYSICAL',
//     maxscore: 2,
//     minscore: -2.3,
//     medianscore: 0.5,
//   },
//   {
//     name: 'SOCIAL',
//     maxscore: 2,
//     minscore: -2.3,
//     medianscore: 0.5,
//   },
//   {
//     name: 'SPIRITUAL',
//     maxscore: 2,
//     minscore: -2.3,
//     medianscore: 0.5,
//   },
// ];

export default function ScoringByDimension() {
  const { company } = useMe();
  //console.log("code:::",company?.code)
  const {data: dataDim} = useList({
    resource: "dimension-score",
    config: {
      filters: [
        {
          field: "employee_code",
          operator: "eq",
          value: company?.code,
        },
      ],
    },
  })
  const Dim = dataDim?.data
  //console.log({Dim})
    return (
      <div style={{ width: '100%', height: 350, minWidth:850 }}>
        <ResponsiveContainer>
          <ComposedChart
            //minWidth={850}
            height={450}
            data={Dim}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 0,
            }}
            stackOffset="sign"
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="dimension" style={{fontSize:12}} interval={0} />
            <YAxis />
            <Tooltip
              formatter={(value, name) => {
                if (name === "max_score") return [value, "max score"];
                if (name === "min_score") return [value, "min score"];
                if (name === "median_score") return [value, "median score"];
                return [value, name];
              }}
            />
            <Legend
              formatter={(value) => {
                if (value === "max_score") return "max score";
                if (value === "min_score") return "min score";
                if (value === "median_score") return "median score";
                return value;
              }}
            />
            {/* <Bar dataKey="percentile_25" barSize={20} fill="#cccccc" stackId="background" opacity={0.5} />
            <Bar dataKey="percentile_75" barSize={20} fill="#cccccc" stackId="background" opacity={0.5} /> */}
            <Bar dataKey="max_score" barSize={20} fill="#005E83" stackId="stack" >
              <LabelList dataKey="max_score" position="top" style={{fontSize: 12}} />
            </Bar>
            <Bar dataKey="min_score" barSize={20} fill="#8C2E2E" stackId="stack">
              <LabelList dataKey="min_score" position="top" style={{fontSize: 12}} />
            </Bar>
            <Scatter dataKey="median_score" fill="#E8B03F"/>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
}

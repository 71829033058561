import React from "react";
import tw from "twin.macro";
import { css } from "@emotion/react";

// scss & assets
import "./SocialRegister.scoped.scss";
import "../../../App.scss";
import dayjs from "dayjs";
import logo from "assets/img/logologo.png";
import bgImg from "assets/img/Group.png";
import { Form, Select, Spin, Checkbox } from "@pankod/refine-antd";
import { DatePicker } from "antd";
import { useCustomMutation, useList, useNavigation } from "@pankod/refine-core";
import { InputBorderBottomWrapper, InputPrimary } from "components/Input";
import routerProvider from "@pankod/refine-react-router-v6";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { config } from "config";
import { useLocalStorage } from "hooks/useAuth/useLocalStorage";
import { IUser } from "types/user";
const { Link } = routerProvider;
dayjs.extend(customParseFormat);

export const optionsGender = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

export default function SocialRegister() {
  const [me, setMe] = useLocalStorage<IUser>(config.USER_KEY);
  const [form] = Form.useForm();
  const { mutateAsync: updateUser, isLoading } = useCustomMutation<IUser>();
  const { push } = useNavigation();
  const [cCode, setCCode] = React.useState("");
  const { data: dataRawCompany, isLoading: isLoadingCompany } = useList<any>({
    resource: "company",
    config: {
      pagination: {
        pageSize: 100,
      },
    },
  });

  const companyCode = dataRawCompany?.data.map((item) => item.code);
  const currentCompany = dataRawCompany?.data.find((item) => item.code === cCode);
  const not_allow_register_by_email_list = currentCompany?.portfolio?.employer.not_allow_register_by_email_list;

  const { data: whiteListEmailData } = useList({
    resource: "white-email",
    config: {
      filters: [
        {
          field: "company_id",
          operator: "eq",
          value: currentCompany?.id,
        },
        {
          field: "email",
          operator: "eq",
          value: me?.email,
        },
      ],
      pagination: {
        pageSize: 1,
      },
    },
    queryOptions: {
      enabled: Boolean(currentCompany) && Boolean(me) && not_allow_register_by_email_list,
    },
  });
  const isOkie = not_allow_register_by_email_list ? Boolean(whiteListEmailData?.data?.[0]) : true;
  const handleSubmit = async (values: any) => {
    const result = await updateUser({
      url: `${config.API_URL}/users/${me.id}`,
      method: "patch",
      values: {
        ...values,
        employeeCode: values.employeeCode.toUpperCase(),
      },
      successNotification: {
        type: "success",
        message: "Updated information successfully",
      },
    });
    if (result.data) {
      setMe(result.data);
      window.location.href = "/";
    }
  };
  React.useEffect(() => {
    if (!me) return;
    if (me.username && me.employeeCode && me.dateOfBirth && me.gender) {
      push("/dashboard");
    }
  }, [me, push]);
  return (
    <Spin spinning={isLoading || isLoadingCompany}>
      <div
        className="login-form text-center pageload"
        css={css`
          background: url(${bgImg}) no-repeat top center/cover;
          .column-small {
            width: 45%;
            text-align: left !important;
          }
          button {
            ${tw`w-full border-primary! border transition-all duration-300 p-[15px] rounded-none! hover:bg-primary/[0.2] mt-[70px] mb-[30px]`}
            letter-spacing: 0.5px;
            color: #00899f !important;
          }
        `}
      >
        <Form
          form={form}
          className=" max-w-xl px-10 py-12 bg-white/[0.65] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[20px] w-full"
          onFinish={(values) => {
            handleSubmit(values);
          }}
          css={css`
            box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
            .column {
              width: 48%;
            }
          `}
          initialValues={{
            username: me?.username,
            employeeCode: me?.employeeCode,
            gender: me?.gender,
          }}
        >
          <img src={logo} alt="Logo" className="w-[120px] h-auto mx-auto pb-[30px]" />
          <h1 className="title text-primary text-[25px] font-bold leading-[27px] mb-[40px] tracking-[1px]">
            Finish setting up your account
          </h1>
          {!isOkie && <span className="text-red-500">Registration with this email is not allowed.</span>}
          <div className="">
            <InputBorderBottomWrapper>
              <Form.Item name="username">
                <InputPrimary type="text" placeholder="Username (optional)" />
              </Form.Item>
            </InputBorderBottomWrapper>

            <InputBorderBottomWrapper>
              <Form.Item
                name="employeeCode"
                rules={[
                  {
                    validator: (_, value) => {
                      const _code = value.toUpperCase();
                      if (!companyCode?.includes(_code)) {
                        return Promise.reject(new Error("Wrong employer code"));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputPrimary
                  type="text"
                  placeholder="Employer Code"
                  onChange={(e) => {
                    setCCode(e.target.value);
                  }}
                />
              </Form.Item>
            </InputBorderBottomWrapper>

            <InputBorderBottomWrapper>
              <Form.Item
                name="dateOfBirth"
                rules={[
                  {
                    required: true,
                    message: "Please enter date of birth",
                  },
                ]}
                className="w-full"
              >
                <DatePicker placeholder="Date of birth (MM/DD/YYYY)" format="MM/DD/YYYY" />
              </Form.Item>
            </InputBorderBottomWrapper>
            <InputBorderBottomWrapper>
              <Form.Item
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "Please enter gender",
                  },
                ]}
              >
                <Select options={optionsGender} placeholder="Birth Sex" className="select-gender" />
              </Form.Item>
            </InputBorderBottomWrapper>
            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error("Please agree to all the terms of service and privacy policy")),
                },
              ]}
              //  {...tailFormItemLayout}
            >
              <Checkbox>
                <p className="link1">
                  I have read the{" "}
                  <Link to="/terms" className="link1">
                    Terms of Services
                  </Link>{" "}
                  and{" "}
                  <Link to="/privacy" className="link1">
                    Privacy Policy
                  </Link>{" "}
                </p>
              </Checkbox>
            </Form.Item>

            <Spin spinning={isLoading}>
              <button
                className="button"
                onClick={() => {
                  if (!isOkie) return;
                  form.submit();
                }}
              >
                SIGNUP
                <div className="button__horizontal"></div>
                <div className="button__vertical"></div>
              </button>
            </Spin>

            <Link to="/login" className="link">
              Back to Login
            </Link>
          </div>
        </Form>
      </div>
    </Spin>
  );
}

import React from 'react';
import { useList } from "@pankod/refine-core";
import { useMe } from "context/MeContext";
import { useCompany } from "context/CompanyContext";
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { createStyles } from 'antd-style';

const useStyle = createStyles(({ css }) => {
  return {
    customTable: css`
      .ant-table {
        .ant-table-container {
          .ant-table-body,
          .ant-table-content {
            scrollbar-width: thin;
            scrollbar-color: unset;
          }
        }
      }
    `,
  };
});
interface DataType {
  key: number;
  age_group: string;
  dimension: string;
  median_score: number;
}
interface DataType1 {
  key: number;
  gender: string;
  dimension: string;
  median_score: number;
}
const columns: TableColumnsType<DataType> = [
  {
    title: 'Age',
    dataIndex: 'age_group',
    width: 120,
  },
  {
    title: 'Dimension',
    dataIndex: 'dimension',
    width: 200,
  },
  {
    title: '',
    dataIndex: 'median_score',
  },
];
const columns1: TableColumnsType<DataType1> = [
  {
    title: 'Gender',
    dataIndex: 'gender',
    width: 120,
  },
  {
    title: 'Dimension',
    dataIndex: 'dimension',
    width: 200,
  },
  {
    title: '',
    dataIndex: 'median_score',
  },
];


const MedianWellnessScore: React.FC = () => {
  const { styles } = useStyle();
  const { company } = useMe();
  const { data: dataAgeLowestScoreDim } = useList({
    resource: "age-lowest-score-dim",
    config: {
      filters: [
        {
          field: "employee_code",
          operator: "eq",
          value: company?.code,
        },
      ],
    },
  });
  const AgeLowestScoreDim = dataAgeLowestScoreDim?.data.map((item: any) => ({
    key: item.id, 
    age_group: item.age_group,
    dimension: item.dimension,
    median_score: item.median_score,
  })) as DataType[];

  const { data: dataGenderLowest } = useList({
    resource: "gender-lowest-score-dim",
    config: {
      filters: [
        {
          field: "employee_code",
          operator: "eq",
          value: company?.code,
        },
      ],
    },
  });
  const GenderLowestScoreDim = dataGenderLowest?.data.map((item: any) => ({
    key: item.id, 
    gender: item.gender,
    dimension: item.dimension,
    median_score: item.median_score,
  })) as DataType1[];
  //console.log("gender:::",GenderLowestScoreDim)
  return (
    <div>
      <h3 className='t-center' style={{color:"#8C2E2E"}}>Dimension with Lowest Median Score</h3>
      <h3>By Gender</h3>
      <Table<DataType1>
        className={styles.customTable}
        columns={columns1}
        dataSource={GenderLowestScoreDim}
        pagination={false}
      />
      <br/>
      <h3>By Age Group</h3>
      <Table<DataType>
        className={styles.customTable}
        columns={columns}
        dataSource={AgeLowestScoreDim}
        pagination={false}
      />
    </div>

  );
};

export default MedianWellnessScore;
import React from "react";
import "./terms.scoped.scss";
import routerProvider from "@pankod/refine-react-router-v6";
import { DoubleLeftOutlined } from "@ant-design/icons";
const { Link } = routerProvider;

const Terms = () => {
  return (
    <div className="terms-page pageload">
      <div className="container">
        <h1>Terms and Conditions</h1>

        <p>
          Welcome to Good Life Wellness Solutions, a wellness program offered by your employer and provided to you by
          Monument Health, LLC (referred to herein as "Good Life"). The Good Life website is a health and wellness
          website ("Portal") that is comprised of various web pages operated by Good Life. Access to the Portal is
          offered to you conditioned on your acceptance without modification of the terms, conditions, and notices
          contained herein. These Terms and Conditions of Use (“Terms”) create an agreement between you as a website
          user (“User”) and Monument Health, LLC regarding the Good Life Wellness Solutions Portal.
        </p>
        <p>
          Your use of Good Life Portal constitutes your agreement to all such Terms. Please read these Terms carefully,
          and keep a copy of them for your reference.{" "}
          <b>If you do not agree to these Terms, please do not use this Portal and website.</b>
        </p>
        <p>
          Good Life Wellness Solutions is a whole health focused wellness platform using health data, information
          provided by Users, and healthcare common themes to generate action plans which allow individual Users to take
          part in developing a system that will aid them in being well.
        </p>
        <h3>Privacy</h3>
        <p>
          Your use of the Portal is subject to Good Life's Privacy Policy, and conditioned on your agreement thereto.
          Please review our Privacy Policy, which also governs the Portal and informs Users of our data collection
          practices.
        </p>
        <h3>Electronic Communications</h3>
        <p>
          Visiting the Portal or sending emails to Good Life constitutes electronic communications. You consent to
          receive electronic communications and you agree that all agreements, notices, disclosures and other
          communications that we provide to you electronically, via email and on the Portal, satisfy any legal
          requirement that such communications be in writing.
        </p>
        <h3>Your Account</h3>
        <p>
          If you use this Portal, you are responsible for maintaining the confidentiality of your account and password
          and for restricting access to your computer, and you agree to accept responsibility for all activities that
          occur under your account or password. You may not assign or otherwise transfer your account to any other
          person or entity. You acknowledge that Good Life is not responsible for third party access to your account
          that results from theft, misappropriation of your account, or other unauthorized access.
        </p>
        <p>
          Good Life and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or
          edit content in our sole discretion.
        </p>
        <h3>Children Under Thirteen</h3>
        <p>
          Good Life does not knowingly collect, either online or offline, personal information from persons under the
          age of thirteen. If you are under 18, you may use the Portal only with permission of a parent or guardian.
        </p>
        <h3>Links to Third Party Sites/Third Party Services</h3>
        <p>
          The Portal may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of
          Good Life and Good Life is not responsible for the contents of any Linked Site, including without limitation
          any link contained in a Linked Site, or any changes or updates to a Linked Site. Good Life is providing these
          links to you only as a convenience, and the inclusion of any link does not imply endorsement by Good Life of
          the site or any association with its operators. Good Life is not responsible for the privacy practices of any
          Linked Site.
        </p>
        <p>
          Certain services made available via the Portal are delivered by third party sites and organizations. By using
          any product, service or functionality originating from the Good Life domain, you hereby acknowledge and
          consent that Good Life may share such information and data with any third party with whom Good Life has a
          contractual relationship to provide the requested product, service or functionality on behalf of Portal Users
          and customers.
        </p>
        <h3>No Unlawful or Prohibited Use/Intellectual Property</h3>
        <p>
          You are granted a non-exclusive, non-transferable, revocable license to access and use the Portal strictly in
          accordance with these terms of use. As a condition of your use of the Portal, you warrant to Good Life that
          you will not use the Portal for any purpose that is unlawful or prohibited by these Terms. You may not use the
          Portal in any manner which could damage, disable, overburden, or impair the Portal or interfere with any other
          party's use and enjoyment of the Portal. You may not obtain or attempt to obtain any materials or information
          through any means not intentionally made available or provided for through the Portal.
        </p>
        <p>
          All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation
          thereof, and any software used on the Portal, is the property of Good Life or its suppliers and protected by
          copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and
          abide by all copyright and other proprietary notices, legends or other restrictions contained in any such
          content and will not make any changes thereto.
        </p>
        <p>
          You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create
          derivative works, or in any way exploit any of the content, in whole or in part, found on the Portal. Good
          Life content is not for resale. Your use of the Portal does not entitle you to make any unauthorized use of
          any protected content, and in particular you will not delete or alter any proprietary rights or attribution
          notices in any content. You will use protected content solely for your personal use, and will make no other
          use of the content without the express written permission of Good Life and the copyright owner. You agree that
          you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or
          implied, to the intellectual property of Good Life or our licensors except as expressly authorized by these
          Terms.
        </p>
        <h3>Third Party Accounts</h3>
        <p>
          You will be able to connect your Good Life account to third party accounts. By connecting your Good Life
          account to your third-party account, you acknowledge and agree that you are consenting to the continuous
          release of information about you to others (in accordance with your privacy settings on those third party
          sites). If you do not want information about you to be shared in this manner, do not use this feature.
        </p>
        <h3>International Users</h3>
        <p>
          The Service is controlled, operated and administered by Good Life from our offices within the USA. If you
          access the Service from a location outside the USA, you are responsible for compliance with all local laws.
          You agree that you will not use the Good Life Content in any country or in any manner prohibited by any
          applicable laws, restrictions or regulations.
        </p>
        <h3>Indemnification</h3>
        <p>
          You agree to indemnify, defend and hold harmless Good Life, its officers, directors, employees, agents and
          third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating
          to or arising out of your use of or inability to use the Portal or services, any User postings made by you,
          your violation of any terms of this Agreement or your violation of any rights of a third party, or your
          violation of any applicable laws, rules or regulations. Good Life reserves the right, at its own cost, to
          assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which
          event you will fully cooperate with Good Life in asserting any available defenses.
        </p>
        <h3>Liability Disclaimer</h3>
        <p>
          THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE PORTAL MAY INCLUDE
          INACCURACIES, TYPOGRAPHICAL ERRORS, OR OUTDATED INFORMATION. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION
          HEREIN, HOWEVER GOODLIFE HAS NO OBLIGATION TO UPDATE THE INFORMATION PRESENTED ON THE PORTAL. GOOD LIFE
          WELLNESS SOLUTIONS AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE PORTAL AT ANY TIME WITHOUT
          NOTICE.
        </p>
        <p>
          GOOD LIFE WELLNESS SOLUTIONS AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY,
          AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
          CONTAINED ON THE PORTAL FOR ANY PURPOSE. GOOD LIFE DOES NOT GUARANTEE THAT THE INFORMATION PROVIDED ON THE
          PORTAL WILL CORRECT AND WITHOUT ERRORS, OR THAT THE PORTAL IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
          INFORMATION OR ADVICE RECEIVED THROUGH USE OF THE PORTAL SHOULD NOT BE RELIED UPON FOR PERSONAL, MEDICAL,
          LEGAL OR FINANCIAL DECISIONS AND YOU SHOULD CONSULT AN APPROPRIATE PROFESSIONAL FOR ADVICE TAILORED TO YOUR
          SITUATION. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS,
          SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. GOOD LIFE
          WELLNESS SOLUTIONS AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS
          INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS
          OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON- INFRINGEMENT.
        </p>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL GOOD LIFE WELLNESS SOLUTIONS AND/OR ITS
          SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY
          DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR
          IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE PORTAL, WITH THE DELAY OR INABILITY TO USE THE PORTAL
          OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE,
          PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE PORTAL, OR OTHERWISE ARISING OUT OF THE USE OF
          THE PORTAL, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF GOOD LIFE
          WELLNESS SOLUTIONS OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME
          STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
          DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE PORTAL, OR
          WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE PORTAL.
        </p>
        <h3>Termination/Access Restriction</h3>
        <p>
          Good Life reserves the right, in its sole discretion, to terminate your access to the Portal and the related
          services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this
          agreement is governed by the laws of the State of Colorado and you hereby consent to the exclusive
          jurisdiction and venue of courts in Colorado in all disputes arising out of or relating to the use of the
          Portal. Use of the Portal is unauthorized in any jurisdiction that does not give effect to all provisions of
          these Terms, including, without limitation, this section. Good Life makes no representation that the
          information in this Portal is appropriate or available for use in locations outside of the State of Colorado,
          USA.
        </p>
        <p>
          You agree that no joint venture, partnership, employment, or agency relationship exists between you and Good
          Life as a result of this agreement or use of the Portal. Good Life's performance of this agreement is subject
          to existing laws and legal process, and nothing contained in this agreement is in derogation of Good Life's
          right to comply with governmental, court and law enforcement requests or requirements relating to your use of
          the Portal or information provided to or gathered by Good Life with respect to such use. If any part of this
          agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited
          to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable
          provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of
          the original provision and the remainder of the agreement shall continue in effect.
        </p>
        <p>
          Unless otherwise specified herein, this agreement constitutes the entire agreement between the User and Good
          Life with respect to the Portal and it supersedes all prior or contemporaneous communications and proposals,
          whether electronic, oral or written, between the User and Good Life with respect to the Portal. A printed
          version of these Terms and of any notice given in electronic form shall be admissible in judicial or
          administrative proceedings to the same extent and subject to the same conditions as other business documents
          and records maintained in printed form. It is the express wish of the parties that this agreement and all
          related documents be written in English.
        </p>
        <h3>Changes to Terms</h3>
        <p>
          Good Life reserves the right, in its sole discretion, to change the Terms under which the Portal is offered.
          The most current version of the Terms will supersede all previous versions. Good Life encourages you to
          periodically review the Terms to stay informed of our updates.
        </p>
        <h3>Contact Us</h3>
        <p>Good Life welcomes your questions or comments regarding the Terms:</p>
        <h2>Good Life Wellness Solutions</h2>
        <p>744 Horizon Court, Suite 260, Grand Junction, Colorado 81506</p>
        <p>Email Address: support@monumenthealth.net</p>
        <p>Telephone number: 970-683-5630</p>
        <p>Effective as of January 01, 2023.</p>
        <div className="button-next">
          <Link to="/register" className="link">
            <DoubleLeftOutlined className="icon" /> Back
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Terms;

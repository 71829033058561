import { SearchOutlined } from "@ant-design/icons";
import { CloneButton, DeleteButton, EditButton, Input, List, Table, useTable } from "@pankod/refine-antd";
import { CrudFilters } from "@pankod/refine-core";
import { useMe } from "context/MeContext";
import dayjs from "dayjs";
import React from "react";
import { useDebounceValue } from "usehooks-ts";

export const WhiteEmailList: React.FC = () => {
  const { me, company, isSuperAdmin } = useMe();
  const [searchValue, setSearchValue] = useDebounceValue("", 500);

  const filters: CrudFilters = isSuperAdmin
    ? []
    : [
        {
          field: "company_id",
          operator: "eq",
          value: company.id,
        },
      ];
  const { tableProps } = useTable({
    resource: "white-email",
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    permanentFilter: filters.concat([
      {
        field: "email",
        operator: "containss",
        value: searchValue,
      },
    ]),
    queryOptions: {
      enabled: Boolean(me) && Boolean(company),
    },
    metaData: {
      join: ["company"],
    },
  });
  const columns = [
    {
      title: "Company Name",
      dataIndex: ["company", "name"],
      key: "company.name",
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "UpdatedAt",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (val: string) => {
        return <>{dayjs(val).format("YYYY-MM-DD HH:mm:ss")}</>;
      },
    },
    {
      title: "Actions",
      render: (_: any, record: any) => {
        return (
          <div className="flex space-x-2">
            <EditButton shape="circle" hideText recordItemId={record.id} />
            <DeleteButton shape="circle" hideText recordItemId={record.id} resourceNameOrRouteName="white-email" />
          </div>
        );
      },
      width: "10%",
    },
  ];
  return (
    <List
      title={<h1 style={{ fontSize: 35, marginBottom: 30, color: "rgb(4, 6, 10, 0.8)" }}>whitelist</h1>}
      canCreate
    >
      <Input
        addonAfter={<SearchOutlined />}
        className={"max-w-[500px] w-full mb-2"}
        placeholder="Search by email ..."
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      />
      <Table {...tableProps} rowKey="id" columns={columns} />
    </List>
  );
};

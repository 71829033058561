import React from "react";
import { css } from "@emotion/react";
import logo from "assets/img/logologo.png";
import bgImg from "assets/img/Group.png";
import { DatePicker, Form, Select, useForm, Checkbox } from "@pankod/refine-antd";
import { SignupButton } from "components/Buttons/Buttons";
import { GroupSocialNetwork } from "components/Icons/GroupSocialNetwork";
import { InputBorderBottomWrapper, InputPrimary } from "components/Input";
import { optionsGender } from "../SocialRegister/SocialRegister";
import routerProvider from "@pankod/refine-react-router-v6";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useList } from "@pankod/refine-core";
import { useDebounceValue } from "usehooks-ts";
const { Link } = routerProvider;
dayjs.extend(customParseFormat);
const dateFormatList = ["MM/DD/YYYY"];

export default function Register() {
  const [form] = Form.useForm();
  const { formProps, mutationResult } = useForm({
    resource: "auth/signup",
    onMutationSuccess: () => {
      window.location.href = "/login";
    },
    submitOnEnter: true,
    redirect: false,
    successNotification: {
      type: "success",
      message: `Your account has been successfully created.`,
    },
    errorNotification: false,
  });
  const error = mutationResult?.error;

  const [cCode, setCCode] = React.useState("");
  const { data: dataRawCompany } = useList<any>({
    resource: "company",
    config: {
      pagination: {
        pageSize: 100,
      },
    },
  });

  const companyCode = dataRawCompany?.data.map((item) => item.code);
  const currentCompany = dataRawCompany?.data.find((item) => item.code === cCode);
  const not_allow_register_by_email_list = currentCompany?.portfolio?.employer.not_allow_register_by_email_list;
  const [email, setEmail] = useDebounceValue("", 500);
  const { data: whiteListEmailData } = useList({
    resource: "white-email",
    config: {
      filters: [
        {
          field: "company_id",
          operator: "eq",
          value: currentCompany?.id,
        },
        {
          field: "email",
          operator: "eq",
          value: email,
        },
      ],
      pagination: {
        pageSize: 1,
      },
    },
    queryOptions: {
      enabled: Boolean(currentCompany) && Boolean(email) && not_allow_register_by_email_list,
    },
  });
  const isOkie = not_allow_register_by_email_list ? Boolean(whiteListEmailData?.data?.[0]) : true;

  return (
    <div
      className="login-form text-center pageload"
      css={css`
        * {
          font-size: 16px;
          line-height: 24px;
          font-weight: 300;
          color: #00899f !important;
        }

        background: url(${bgImg}) no-repeat top center/cover;
        height: 100vh;
        .column-small {
          width: 45%;
          text-align: left !important;
        }
        @media (max-width: 768px) {
          height: 100%;
          .column-small {
            width: 100%;
            text-align: left !important;
          }
          .responsive {
            display: inline;
          }
          .column {
            width: 100% !important;
          }
          .responsive-checkbox {
            display: inline;
            text-align: center !important;
            align-items: center;
            margin: auto;
          }
        }
        a,
        .ant-select-selection__placeholder {
          color: #00899f !important;
        }
        .error {
          color: red !important;
        }
      `}
    >
      <Form
        {...formProps}
        form={form}
        onFinish={(e: any) => {
          const values = {
            ...e,
            username: e.username.toLowerCase(),
          };
          formProps.onFinish?.(values);
        }}
        className="max-w-5xl px-10 bg-white/[0.65] rounded-[20px] w-full"
        css={css`
          // box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
          .column {
            width: 48%;
          }
          padding: 3rem 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          @media (max-width: 768px) {
            position: relative;
            top: 0;
            left: 0;
            transform: none;
            height: 100% !important;
            padding: 2rem auto !important;
          }
          button:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        `}
      >
        <img src={logo} alt="Logo" className="w-[120px] h-auto mx-auto pb-[30px]" />
        <h1 className="title text-[25px] font-bold leading-[27px] mb-[40px] tracking-[1px]">SIGN UP</h1>
        {error?.message && <span className="text-red-500 px-2">{error?.message}</span>}
        {!isOkie && <span className="error">Registration with this email is not allowed</span>}
        <div className="j-between responsive">
          {/* column 1 */}
          <div className="column">
            <div className="j-between responsive">
              <div className="column-small">
                <InputBorderBottomWrapper>
                  <Form.Item
                    name="firstname"
                    rules={[
                      {
                        required: true,
                        message: "Please enter first name",
                      },
                    ]}
                  >
                    <InputPrimary type="text" placeholder="First name" />
                  </Form.Item>
                </InputBorderBottomWrapper>
              </div>
              <div className="column-small">
                <InputBorderBottomWrapper>
                  <Form.Item
                    name="lastname"
                    rules={[
                      {
                        required: true,
                        message: "Please enter last name",
                      },
                    ]}
                  >
                    <InputPrimary type="text" placeholder="Last name" />
                  </Form.Item>
                </InputBorderBottomWrapper>
              </div>
            </div>

            <InputBorderBottomWrapper>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please enter username",
                  },
                ]}
                // normalize={value => value.toLowerCase()}
              >
                <InputPrimary type="text" placeholder="Username" />
              </Form.Item>
            </InputBorderBottomWrapper>

            <div className="j-between responsive">
              <div className="column-small">
                <InputBorderBottomWrapper>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        min: 6,
                      },
                    ]}
                  >
                    <InputPrimary type="password" placeholder="Password" autoComplete="off" />
                  </Form.Item>
                </InputBorderBottomWrapper>
              </div>
              <div className="column-small">
                <InputBorderBottomWrapper>
                  <Form.Item
                    name="passwordConfirm"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error("The two passwords that you entered do not match!"));
                        },
                      }),
                    ]}
                  >
                    <InputPrimary type="password" placeholder="Confirm Password" />
                  </Form.Item>
                </InputBorderBottomWrapper>
              </div>
            </div>

            <InputBorderBottomWrapper>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Your email address is not valid",
                  },
                ]}
              >
                <InputPrimary
                  type="email"
                  placeholder="Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Form.Item>
            </InputBorderBottomWrapper>
            <div className="flex space-x-2">
              <InputBorderBottomWrapper>
                <Form.Item
                  name="employeeCode"
                  rules={[
                    {
                      validator: (_, value) => {
                        const _code = value.toUpperCase();
                        if (!companyCode?.includes(_code)) {
                          return Promise.reject(new Error("Wrong employer code"));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <InputPrimary
                    type="text"
                    placeholder="Employer Code"
                    onChange={(e) => {
                      setCCode(e.target.value);
                    }}
                  />
                </Form.Item>
              </InputBorderBottomWrapper>
            </div>
          </div>

          {/* column 2 */}
          <div className="column">
            <div className="j-between responsive">
              <div className="column-small">
                <InputBorderBottomWrapper>
                  <Form.Item
                    name="dateOfBirth"
                    rules={[
                      {
                        required: true,
                        message: "Please enter date of birth",
                      },
                    ]}
                    className="w-full"
                  >
                    <DatePicker placeholder="Date of birth (MM/DD/YYYY)" format={dateFormatList} />
                  </Form.Item>
                </InputBorderBottomWrapper>
              </div>
              <div className="column-small">
                <InputBorderBottomWrapper>
                  <Form.Item
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: "Please choose gender",
                      },
                    ]}
                  >
                    <Select options={optionsGender} placeholder="Birth Sex" className="select-gender" />
                  </Form.Item>
                </InputBorderBottomWrapper>
              </div>
            </div>
            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error("Please agree to all the terms of service and privacy policy")),
                },
              ]}
              className="responsive-checkbox"
            >
              <Checkbox>
                I have read the{" "}
                <Link to="/terms" style={{ borderBottom: "1px solid", paddingBottom: 3}}>
                  Terms of Services{" "}
                </Link>
                &{" "}
                <Link to="/privacy" style={{ borderBottom: "1px solid", paddingBottom: 3}}>
                  Privacy Policy
                </Link>
              </Checkbox>
            </Form.Item>

            <SignupButton type="submit" disabled={!isOkie} />

            <p className="text-opacity-50">
              Already A Member?
              <a
                className="font-semibold"
                href="/login"
                css={css`
                  &:hover {
                    color: rgba(217, 162, 42, 1) !important;
                  }
                `}
              >
                {" "}
                Login Now.
              </a>
            </p>
            <div className="j-between items">
              <div className="line left j-item" />
              <p className="text-opacity-50 connect-text j-item">Or connect with</p>
              <div className="line right j-item" />
            </div>

            <GroupSocialNetwork />
          </div>
        </div>
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://monumenthealth.net/goodlife-tutorials/"
          css={css`
            font-style: italic;
            &:hover {
              color: var(--c-orange) !important;
              font-weight: 400;
            }
          `}
        >
          Click here to learn how to register and log in with our tutorial videos
        </a>
      </Form>
    </div>
  );
}

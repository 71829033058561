import { useForm, Form, Input, Row, Col, Create, Select, useSelect } from "@pankod/refine-antd";
import { useMe } from "context/MeContext";
import React from "react";
export const WhiteEmailCreate: React.FC = () => {
  const { company, isSuperAdmin } = useMe();
  const { formProps, saveButtonProps } = useForm({
    resource: "white-email",
  });

  const { selectProps } = useSelect({
    resource: "company",
    optionLabel: "name",
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          company_id: company?.id,
        }}
      >
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={11}>
            <Form.Item label={"Company"} name={"company_id"}>
              <Select {...selectProps} disabled={!isSuperAdmin} />
            </Form.Item>
            <Form.Item label={"Email"} name={"email"}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};

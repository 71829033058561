import React, { useState, useEffect } from "react";
import { Layout, Menu, Grid } from "antd";
import { LogoutOutlined, UnorderedListOutlined } from "@ant-design/icons";
import {
  useTranslate,
  useLogout,
  CanAccess,
  ITreeMenu,
  useIsExistAuthentication,
  useRouterContext,
  useMenu,
} from "@pankod/refine-core";
import logo from "assets/img/mhlogo.png";
import { css } from "@emotion/react";

import { antLayoutSider, antLayoutSiderMobile } from "./styles";
import { RefineLayoutSiderProps } from "@pankod/refine-ui-types";
import { useMe } from "context/MeContext";
const { SubMenu } = Menu;

export const Sider: React.FC<RefineLayoutSiderProps> = ({ render }) => {
  const { isSuperAdmin } = useMe();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const isExistAuthentication = useIsExistAuthentication();
  const { Link } = useRouterContext();
  const { mutate: mutateLogout } = useLogout();
  const translate = useTranslate();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const breakpoint = Grid.useBreakpoint();

  const isMobile = typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
    return tree.map((item: ITreeMenu) => {
      const { icon, label, route, name, children, parentName, key } = item;
      if (children.length > 0) {
        return (
          <CanAccess
            key={name}
            resource={name.toLowerCase()}
            action="list"
            params={{
              resource: item,
            }}  
          >
            <SubMenu
              key={name} 
              title={label} 
            >
              {!collapsed && renderTreeView(children, selectedKey)}
            </SubMenu>
          </CanAccess>
        );
      }
      const isSelected = route === selectedKey;
      const isRoute = !(parentName !== undefined && children.length === 0);
      return (
        <CanAccess
          key={route}
          resource={name.toLowerCase()}
          action="list"
          params={{
            resource: item,
          }}
        >
          <Menu.Item
            key={route}
            style={{
              //fontWeight: isSelected ? "bold" : "normal",
              backgroundColor: isSelected ? "whitesmoke" : "",
            }}
          >
            <Link to={route}
              style={{
                fontWeight: isSelected ? "500" : "400",
                color: isSelected ? "#04060a" : "rgb(4, 6, 10, 0.5)",
              }}
            >{label}</Link>
            {!collapsed && isSelected && <div className="ant-menu-tree-arrow" />}
          </Menu.Item>
        </CanAccess>
      );
    });
  };

  const logout = isExistAuthentication && (
    <Menu.Item key="logout" onClick={() => mutateLogout()} icon={<LogoutOutlined />}>
      {translate("buttons.logout", "Logout")}
    </Menu.Item>
  );

  const dashboard = null;
  const whiteListItems = [
    "AdminDashboard",
    "dashboard",
    // "admin",
    "admin1",
    "activity",
    "challenge",
    "icons",
    "notifications",
    "hrasettings",
    "hraadmin",
    "forum",
    "team",
    "location",
    "jobtitle",
    "employeetype",
    "metadata",
  ];
  // const _menuItems = menuItems.filter((item) => {
  //   if (isSuperAdmin) return true;
  //   return whiteListItems.includes(item.name.toLocaleLowerCase());
  // });
  const _menuItems = menuItems.filter((item) => {
    const lowerCaseName = item.name.toLocaleLowerCase();

    if (isSuperAdmin) {
        return lowerCaseName !== "admin1";
    }

    return whiteListItems.includes(lowerCaseName);
});


  const items = renderTreeView(_menuItems, selectedKey);

  const renderSider = () => {
    if (render) {
      return render({
        dashboard,
        items,
        logout,
        collapsed,
      });
    }
    return (
      <>
        {dashboard}
        {items}
        {/* {logout} */}
      </>
    );
  };

  return (
    <Layout.Sider
      css={css`
        background: white !important ;
        span {
          padding-left: 10px;
          font-weight: 400;
        }
        .logo {
          width: 40%;
          margin: auto;
          padding: 20px 0;
        }
        .ant-menu-light.ant-menu-root.ant-menu-inline {
          border-inline-end: none !important;
        }
        border-right: 1px solid rgba(124, 124, 124, 0.4);
        .ant-menu-submenu-title {
          color:rgba(4, 6, 10, 0.5) !important;
        }
        .sub-item {
          margin-left: 20px;
          width: 88%;
        }
      `}
      breakpoint="lg"
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
    >
      <div className="logo">
        <img src={logo} alt="Monument Health logo"/>
      </div>
      <Menu
        selectedKeys={[selectedKey]}
        defaultOpenKeys={["hrasettings","activities","system"]}
        mode="inline"
        onClick={() => {
          if (!breakpoint.lg) {
            setCollapsed(true);
          }
        }}
      >
        {renderSider()}
      </Menu>
    </Layout.Sider>
  );
};

import React from "react";
import "./privacy.scoped.scss";
import routerProvider from "@pankod/refine-react-router-v6";
import { DoubleLeftOutlined } from "@ant-design/icons";
const { Link } = routerProvider;

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-page pageload">
      <div className="container">
        <h1>Privacy Policy</h1>
        <p>
          This Privacy Policy is prepared by Monument Health, LLC, a Colorado limited liability company (“We”). We are
          committed to protecting and preserving the privacy of visitors to our website and those who communicate
          electronically with us.
        </p>
        <p>
          This policy sets out how we process any personal data we collect from you or that you provide to us through
          our website and social media sites. We confirm that we will keep your information secure and comply fully with
          all applicable United States data protection laws and regulations. Please read the following carefully to
          understand our practices that apply to personal data that you provide to us, or that we collect from you when
          you visit our websites. By submitting information you are accepting and consenting to the practices described
          in this policy.
        </p>

        <h3>Types of information we may collect from you</h3>
        <p>
          We may collect, store and use the following kinds of personal information about individuals who visit and use
          our website and social media sites:
        </p>
        <p>&#x2022; Information you supply to us by using our website.</p>
        <p>
          &#x2022; Information about you in forms submitted by you on our website or social media. This includes
          information you provide when you submit a contact/inquiry form.{" "}
        </p>
        <p>&#x2022; The information you give us, including , your name, address, e-mail address, and phone number.</p>

        <h3>How we may use the information we collect</h3>
        <p>We use the information in the following ways:</p>
        <p>&#x2022; To provide you with information and/or services that you request from us;</p>
        <p>&#x2022; To contact you to provide the information requested.</p>

        <h3>Disclosure of your information</h3>
        <p>
          Any information you provide to us will either be emailed directly to us or may be stored on a secure server.
        </p>
        <p>
          We do not rent, sell or share personal information about you with other people or non-affiliated companies.
        </p>
        <p>
          We will use reasonable efforts to keep your personal data secure and take measures prevent disclosure to
          third-parties unless permitted or required by law or other regulations.
        </p>
        <p>
          Unfortunately, the transmission of information via the internet is not completely secure. Although we use
          reasonable efforts to protect your personal data, we cannot guarantee the security of your data transmitted to
          our site. By using and accessing our website, or communicating through or submitting information through use
          of our website, you acknowledge that any transmission of information is at your own risk. Once we have
          received your information, we will use administrative procedures and information security practices and
          features to try to prevent unauthorized access or disclosure of your information.
        </p>

        <h3>Your rights – access to your personal data</h3>
        <p>
          You have the right to ensure that your personal information and data is being processed lawfully. Any request,
          questions or complaints you have about how your information is processed, must be made in writing to
          support@monumenthealth.net. To enable us to review and reply to your inquiry or complaint we may need to
          request further information from you.
        </p>

        <h3>Changes to our privacy policy</h3>
        <p>
          Any changes we may make to our privacy policy in the future will be posted on this page. Please check back
          frequently to see any updates or changes to our privacy policy.
        </p>

        <h3>Contact</h3>
        <p>
          Questions, comments, and requests regarding this privacy policy are welcomed and should be addressed to
          Monument Health support@monumenthealth.net.
        </p>
        <div className="button-next">
          <Link to="/register" className="link">
            <DoubleLeftOutlined className="icon" /> Back
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

import React from "react";
import "./Login.scoped.scss";
import logo from "assets/img/logologo.png";
import { css } from "@emotion/react";
import { Form } from "@pankod/refine-antd";
import { GroupSocialNetwork } from "components/Icons/GroupSocialNetwork";
import { LoginButton } from "components/Buttons/Buttons";
import { InputBorderBottomWrapper, InputPrimary } from "components/Input";
import { useApiUrl, useCustomMutation } from "@pankod/refine-core";
import { config } from "config";
import { useLocalStorage } from "hooks/useAuth/useLocalStorage";
import { useCompany } from "context/CompanyContext";
import routerProvider from "@pankod/refine-react-router-v6";
const { Link } = routerProvider;

export default function Login() {
  const apiUrl = useApiUrl();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [__, setTokens] = useLocalStorage<any>(config.TOKEN_KEY, {});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setUser] = useLocalStorage<any>(config.USER_KEY, {});
  const { mutateAsync: login, error, data, isLoading } = useCustomMutation();
  const handleSubmit = async (values: any) => {
    login({
      url: `${apiUrl}/auth/signin/`,
      method: "post",
      values: {
        username: values.username.toLowerCase(),
        password: values.password,
      },
      errorNotification: false,
    });
  };
  const _data = data?.data;
  const { path: pathCompany } = useCompany();
  React.useEffect(() => {
    if (!isLoading && _data) {
      setTokens(_data.tokens);
      setUser(_data.user);
      window.location.href = `/${pathCompany}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_data, isLoading]);
  return (
    <div className={"login-form pageload"}>
      <Form
        className="w-full max-w-xl px-10 py-12"
        onFinish={(values) => {
          handleSubmit(values);
        }}
      >
        <img src={logo} alt="Logo" className={"logo"} />
        <h1 className={"title"}>LOGIN</h1>
        {error?.message && <span className="text-red-600 px-2">{error?.message}</span>}
        <InputBorderBottomWrapper>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please enter username",
              },
            ]}
          >
            <InputPrimary className={"input"} type="input" placeholder="Username" />
          </Form.Item>
        </InputBorderBottomWrapper>
        <InputBorderBottomWrapper>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your password",
              },
            ]}
          >
            <InputPrimary className={"input"} type="password" placeholder="Password" />
          </Form.Item>
        </InputBorderBottomWrapper>

        <Link to="/forgotpassword" className={"forgot-password"}>
          Forgot Password?
        </Link>
        <LoginButton />
        <p className="text-opacity-50">
          Not A Member?
          <a href="/register" className="create-acc">
            {" "}
            Create An Account.
          </a>
        </p>
        <div className="j-between items-group">
          <div className="lines left j-item" />
          <p className={`connect-text text-opacity-50 j-item`}>Or connect with</p>
          <div className="lines right j-item" />
        </div>
        <GroupSocialNetwork />
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://monumenthealth.net/goodlife-tutorials/"
          css={css`
            font-style: italic;
            color: #00899f !important;
            &:hover {
              color: var(--c-orange) !important;
              font-weight: 400;
            }
          `}
        >
          Click here to learn how to register and log in with our tutorial videos
        </a>
      </Form>
    </div>
  );
}

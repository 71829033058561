import { useForm, Form, Input, Row, Col, Select, useSelect, Edit } from "@pankod/refine-antd";
import { useParams } from "@pankod/refine-react-router-v6";
import { useMe } from "context/MeContext";
import React from "react";
export const WhiteEmailEdit: React.FC = () => {
  const { id } = useParams();
  const { isSuperAdmin } = useMe();
  const { formProps, saveButtonProps, formLoading } = useForm({
    resource: "white-email",
    id,
  });

  const { selectProps } = useSelect({
    resource: "company",
    optionLabel: "name",
  });
  if (formLoading) return null;
  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={11}>
            <Form.Item label={"Company"} name={"company_id"}>
              <Select {...selectProps} disabled={!isSuperAdmin} />
            </Form.Item>
            <Form.Item
              label={"Email"}
              name={"email"}
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Your email address is not valid",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};

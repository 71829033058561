import {
  useForm,
  Form,
  Input,
  Row,
  Col,
  InputNumber,
  DatePicker,
  Edit,
  useSelect,
  Select,
  Switch,
  Button,
} from "@pankod/refine-antd";
import { CardAdmin } from "components/Card/CardAdmin";
import React, { useState, useEffect } from "react";
import { UploadImage } from "components/Upload/Upload";
import { DataType } from "types/challenge";
import { ListStats } from "./components/ListStats";
import { useParams } from "@pankod/refine-react-router-v6";
import dayjs from "dayjs";
import { config } from "config";
import { DiffListStats } from "./components/DiffListStats";
import "./challengeAdmin.scoped.scss";


interface FormValues {
  company_ids: string[];
}
export const dataTypeOps = [
  {
    value: DataType.Numeric,
    label: DataType.Numeric,
  },
  {
    value: DataType["Yes/No"],
    label: DataType["Yes/No"],
  },
  {
    value: DataType.Dropdown,
    label: DataType.Dropdown,
  },
  {
    value: DataType.Date,
    label: DataType.Date,
  },
  {
    value: DataType.FreeText,
    label: DataType.FreeText,
  },
];

export const ChallengeEdit: React.FC = () => {
  const { id } = useParams();

  const { formProps, saveButtonProps, form, queryResult, formLoading } = useForm({
    resource: "challenge",
    id,
    redirect: false,
    metaData: {
      join: {
        field: "companies",
      },
    },
  });
  const { selectProps } = useSelect({
    resource: "company",
    optionLabel: "name",
    optionValue: "id",
  });
  const [loading, setLoading] = useState(false);
  const data = queryResult?.data?.data;
  const header_img = config.ASSET_URL + data?.header_img;
  const scoring_img = config.ASSET_URL + data?.scoring_img;
  const helpful_img = config.ASSET_URL + data?.helpful_img;

  const handlePreview = async () => {
    try {
      const values = (await form.validateFields()) as FormValues;
      const companies = values.company_ids.map((id: string) => ({
        id,
      }));

      // Set preview flag
      formProps.onFinish?.({ ...values, companies, preview: true });
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };
  const mode = Form.useWatch("mode", form);

  const weeks = Form.useWatch("duration", form);
  const _diff_stats = Array.from(Array(weeks).keys()).map((a) => ({
      week: a,
      stats: [
          {
              title: "",
              description: "",
              data_type: "",
          },
      ],
  }));

  // useEffect(() => {
  //   form.setFieldValue("diff_stats", _diff_stats);
  // }, [weeks]);

  // if (loading) {
  //     return <div>Loading...</div>; // Show loading state
  // }

  useEffect(() => {
    // Get the current diff_stats value
    const currentDiffStats = form.getFieldValue("diff_stats") || [];
  
    // Append new weeks' data to the existing diff_stats
    const updatedDiffStats = [
      ...currentDiffStats.slice(0, weeks), // Keep the first 'weeks' columns
      ..._diff_stats.slice(currentDiffStats.length, weeks), // Append new weeks' data
    ];
    form.setFieldValue("diff_stats", updatedDiffStats);
  }, [weeks]);
  return (
    <Edit
      saveButtonProps={{ ...saveButtonProps, children: "Publish" }}
      footerButtons={({ defaultButtons }) => (
        <>
          {defaultButtons}
          <Button type="default" onClick={handlePreview}>
            Preview
          </Button>
        </>
      )}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          ...formProps.initialValues,
          start_date: dayjs(data?.start_date),
          grace_period: dayjs(data?.grace_period),
          company_ids: data?.companies.map((item: any) => {
            return item.id;
          }),
        }}
        onFinish={(e: any) => {
          const companies = e.company_ids.map((id: string) => {
            return {
              id,
            };
          });
          formProps.onFinish?.({ ...e, companies, preview: false });
        }}
      >
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={8}>
            <CardAdmin title="General information">
              <Form.Item
                label={"Company"}
                name={"company_ids"}
                rules={[
                  {
                    required: true,
                  },
                ]}
                className="w-full"
              >
                <Select {...selectProps} mode="multiple" placeholder="Company" />
              </Form.Item>
              <Form.Item
                label={"Challenge name"}
                name="name"
                className="w-[300px]"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Challenge name" />
              </Form.Item>
              <Form.Item
                className="w-1/2"
                label={"Start date"}
                name="start_date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                className="w-1/2"
                label={"Duration (weeks)"}
                name="duration"
                rules={[
                  {
                    required: true,
                    type: "number",
                  },
                ]}
              >
                <InputNumber min={0} type="number" />
              </Form.Item>

              <Form.Item
                className="w-1/2"
                label={"Grace period"}
                name="grace_period"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item className="w-1/2" label={"Allow teams"} name="allow_team" valuePropName="checked">
                <Switch />
              </Form.Item>
              {/* <Form.Item className="w-1/2" label={"Status"} name="status" valuePropName="checked">
                <Switch />
              </Form.Item> */}
              {/* <Form.Item className="w-1/2" label={"Preview"} name="preview" valuePropName="checked">
                <Switch />
              </Form.Item> */}
              <Form.Item
                label={"Description"}
                name="description"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item
                label={"Header Image"}
                name="header_img"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <UploadImage
                  setLoading={setLoading}
                  loading={loading || formLoading}
                  form={form}
                  isDefault={false}
                  name="header_img"
                  url={!formLoading ? header_img : ""}
                />
              </Form.Item>
              <Form.Item
                label={"Scoring Info Image"}
                name="scoring_img"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <UploadImage
                  setLoading={setLoading}
                  loading={loading || formLoading}
                  form={form}
                  isDefault={false}
                  name="scoring_img"
                  url={!formLoading ? scoring_img : ""}
                />
              </Form.Item>
              <Form.Item
                label={"Helpful Hint Image"}
                name="helpful_img"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <UploadImage
                  setLoading={setLoading}
                  loading={loading || formLoading}
                  form={form}
                  isDefault={false}
                  name="helpful_img"
                  url={!formLoading ? helpful_img : ""}
                />
              </Form.Item>
            </CardAdmin>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item name={"mode"}>
              <Select
                defaultValue="SameWeek"
                style={{ width: 300 }}
                options={[
                  { value: "SameWeek", label: "Same challenges for each week" },
                  { value: "DiffWeek", label: "Different challenges for each week" },
                ]}
              />
            </Form.Item>
            <div className={`${mode === "SameWeek" ? "" : "disabled"}`}>
              <CardAdmin title="Same challenges for each week">
                <ListStats />
              </CardAdmin>
            </div>
            <div className={`${mode === "DiffWeek" ? "" : "disabled"}`}>
              <CardAdmin title="Different challenges for each week">
                <DiffListStats />
              </CardAdmin>
            </div>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};

import React from "react";

import { Authenticated, Refine } from "@pankod/refine-core";
import { notificationProvider, ReadyPage, ErrorComponent } from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";

// scss
import "./App.scss";
import "@pankod/refine-antd/dist/reset.css";
import "./assets/styles/scss/global.scss";

// pages
import Login from "./pages/Auth/Login/Login";
import Register from "./pages/Auth/Register/Register";
import Dashboard from "./pages/Dashboard";
import SocialRegister from "pages/Auth/SocialRegister/SocialRegister";
import Resources from "pages/Resources2024";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Terms from "pages/Terms";
import HRA from "pages/HRA";
import MyAccount from "pages/MyAccount2024";
import ForgotPassword from "pages/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "pages/Auth/ResetPassword/ResetPassword";
import Challenge from "pages/Challenge";
import ActivityPage from "pages/Activity";
import HRAresults from "pages/HRAresults";
import Forums from "pages/Forums";
import ForumsTopics from "pages/ForumsTopics";
import ForumsPosts from "pages/ForumsPosts";
import ForumsNewPost from "pages/ForumsNewPost";
import Contact from "pages/Contact";
import UserDashboard from "pages/User-dashboard";

import { authProviderRest } from "./lib/authProvider";
import { Layout } from "components/Layout";

import { Header } from "components/Layout/Header";
import LoginRedirect from "pages/Auth/LoginRedirect";
import { AdminDashboard } from "pages/Admin/Dashboard";
import { Admin } from "pages/Admin";
import { UserCreate } from "pages/Admin/Users/create";
import { CompanyAdmin } from "pages/Admin/Company";
import { UserEdit } from "pages/Admin/Users/edit";
import { CompanyCreate } from "pages/Admin/Company/create";
import { CompanyEdit } from "pages/Admin/Company/edit";
import { ColorProvider } from "context/ColorContext";
import { QuestionCreate } from "pages/Admin/QuestionDashboard/Question/create";
import { QuestionEdit } from "pages/Admin/QuestionDashboard/Question/edit";
import { QuestionList } from "pages/Admin/QuestionDashboard/Question";
import { Dimension } from "pages/Admin/Dimenson";
import { DimensionCreate } from "pages/Admin/Dimenson/create";
import { DimensionEdit } from "pages/Admin/Dimenson/edit";
import { nestJsDataProvider } from "lib/nestjsCRUDProvider";
import { ActivityCreate } from "./pages/Admin/Activity/create";
import { ActivityEdit } from "./pages/Admin/Activity/edit";
import { Trophy } from "pages/Admin/Trophy";
import { TrophyCreate } from "pages/Admin/Trophy/create";
import { TrophyEdit } from "pages/Admin/Trophy/edit";
import { Activity } from "pages/Admin/Activity";
import { ChallengeCreate } from "pages/Admin/Challenge/create";
import { ChallengeEdit } from "pages/Admin/Challenge/edit";
import { ChallengeAdmin } from "pages/Admin/Challenge";
import { ResourcesAdmin } from "pages/Admin/Resources";
import { ResourcesCreate } from "pages/Admin/Resources/create";
import { Icons } from "pages/Admin/Icons";
import { HRAAdmin } from "pages/Admin/HRAAdmin";
import { ActivityIndividual } from "pages/Admin/ActivityIndividual";
import { CompanyClone } from "pages/Admin/Company/clone";
import { CategoryList } from "pages/Admin/forum/category/list";
import { CategoryCreate } from "pages/Admin/forum/category/create";
import { CategoryEdit } from "pages/Admin/forum/category/edit";
import { TopicList } from "pages/Admin/forum/topic/list";
import { TopicCreate } from "pages/Admin/forum/topic/create";
import { TopicEdit } from "pages/Admin/forum/topic/edit";
import { PostList } from "pages/Admin/forum/post/list";
import { PostCreate } from "pages/Admin/forum/post/create";
import { PostEdit } from "pages/Admin/forum/post/edit";
import { MessageList } from "pages/Admin/forum/message/list";
import { liveProvider } from "@pankod/refine-ably";
import { ablyClient } from "utils/ablyClient";
import { ResourcesEdit } from "pages/Admin/Resources/edit";
import { TeamList } from "pages/Admin/team/list";
import { TeamEdit } from "pages/Admin/team/edit";
import { TeamCreate } from "pages/Admin/team/create";
import { LocationList } from "pages/Admin/location/list";
import { LocationCreate } from "pages/Admin/location/create";
import { LocationEdit } from "pages/Admin/location/edit";
import { JobTitleList } from "pages/Admin/job-title/list";
import { JobTitleCreate } from "pages/Admin/job-title/create";
import { JobTitleEdit } from "pages/Admin/job-title/edit";
import { EmployeeTypeList } from "pages/Admin/employee-type/list";
import { EmployeeTypeCreate } from "pages/Admin/employee-type/create";
import { EmployeeTypeEdit } from "pages/Admin/employee-type/edit";
import { Notifications } from "pages/Admin/Notifications";
import { MessageOutlined } from "@ant-design/icons";
import { WhiteEmailList } from "pages/Admin/WhiteEmail";
import { WhiteEmailCreate } from "pages/Admin/WhiteEmail/create";
import { WhiteEmailEdit } from "pages/Admin/WhiteEmail/edit";

function App() {
  return (
    <ColorProvider>
      <Refine
        notificationProvider={notificationProvider}
        Layout={Layout}
        DashboardPage={Dashboard}
        LoginPage={Login}
        ReadyPage={ReadyPage}
        catchAll={<ErrorComponent />}
        dataProvider={nestJsDataProvider}
        authProvider={authProviderRest}
        Header={Header}
        options={{
          syncWithLocation: true,
        }}
        liveProvider={liveProvider(ablyClient)}
        routerProvider={{
          ...routerProvider,
          routes: [
            {
              element: <Register />,
              layout: false,
              path: "register",
            },
            {
              element: <SocialRegister />,
              layout: false,
              path: "register/social",
            },
            {
              element: <Login />,
              layout: false,
              path: "login",
            },
            {
              element: <LoginRedirect />,
              path: "auth/:provider/callback",
            },
            {
              element: <Dashboard />,
              path: "/:path",
              layout: true,
            },
            {
              element: <Contact />,
              path: "/:path/contact",
              layout: true,
            },
            {
              element: <ActivityPage />,
              path: "/:path/activity",
              layout: true,
            },
            {
              element: <Resources />,
              path: "/:path/resources",
              layout: true,
            },
            {
              element: <Forums />,
              path: "/:path/forums",
              layout: true,
            },
            {
              element: <ForumsTopics />,
              path: "/:path/forums/topic/:topicId",
              layout: true,
            },
            {
              element: (
                <Authenticated>
                  <ForumsPosts />
                </Authenticated>
              ),
              path: "/:path/forums/topic/:topicId/post/:postId",
              layout: true,
            },
            {
              element: (
                <Authenticated>
                  <ForumsNewPost />
                </Authenticated>
              ),
              path: "/:path/forums/topic/:topicId/new-post",
              layout: true,
            },
            {
              element: <PrivacyPolicy />,
              path: "privacy",
              layout: true,
            },
            {
              element: <Terms />,
              path: "terms",
              layout: true,
            },
            {
              element: (
                <Authenticated>
                  <HRA />
                </Authenticated>
              ),
              path: "/:path/hra",
              layout: true,
            },
            {
              element: <MyAccount />,
              path: "myaccount",
              layout: true,
            },
            {
              element: <UserDashboard />,
              path: "user-dashboard",
              layout: true,
            },
            {
              element: <ForgotPassword />,
              path: "forgotpassword",
              layout: false,
            },
            {
              element: <ResetPassword />,
              path: "resetpassword",
              layout: false,
            },
            {
              element: <Challenge />,
              path: "/:path/challenge",
              layout: true,
            },
            {
              element: <HRAresults />,
              path: "/:path/hraresults",
              layout: true,
            },
            {
              element: <CompanyClone />,
              path: "/admin/company/clone/:id",

              layout: true,
            },
          ],
        }}
        resources={[
          {
            name: "AdminDashboard",
            list: AdminDashboard,
            options: {
              route: "admin/dashboard",
              label: "Dashboard",
            },
          },
          {
            name: "admin",
            list: Admin,
            create: UserCreate,
            edit: UserEdit,
            parentName: "system",
            options: {
              route: "user",
              label: "• Users",
            },
          },
          {
            name: "admin1",
            list: Admin,
            create: UserCreate,
            edit: UserEdit,
            options: {
              route: "user",
              label: "Users",
            },
          },
          {
            name: "Employer",
            list: CompanyAdmin,
            create: CompanyCreate,
            edit: CompanyEdit,
            parentName: "system",
            options: {
              route: "company",
              label: "• Companies",
            },
          },
          {
            name: "Notifications",
            list: Notifications,
            parentName: "system",
            options: {
              route: "notifications",
              label: "• Notifications",
            },
          },
          {
            name: "Challenge",
            list: ChallengeAdmin,
            create: ChallengeCreate,
            edit: ChallengeEdit,
            options: {
              route: "admin/challenge",
              label: "Challenges",
            },
          },
          {
            name: "Resources",
            list: ResourcesAdmin,
            create: ResourcesCreate,
            edit: ResourcesEdit,
            options: {
              route: "admin/resources",
              label: "Resources",
            },
          },
          {
            name: "activities",
            list: HRAAdmin,
            options: {
              label: "Activities",
              disabled: true,
              route: "admin/activities",
            },
          },
          {
            name: "Activity",
            list: Activity,
            create: ActivityCreate,
            edit: ActivityEdit,
            parentName: "activities",
            options: {
              route: "activity",
              label: "• Activity List",
            },
          },
          {
            name: "Trophy",
            list: Trophy,
            create: TrophyCreate,
            edit: TrophyEdit,
            parentName: "activities",
            options: {
              route: "trophy",
              label: "• Trophies",
            },
          },
          {
            name: "Icons",
            list: Icons,
            parentName: "activities",
            options: {
              route: "icons",
              label: "• Icons",
            },
          },
          {
            name: "hrasettings",
            list: HRAAdmin,
            options: {
              label: "HRA",
              disabled: true,
              route: "admin/hrasettings",
            },
          },
          {
            name: "Question",
            list: QuestionList,
            create: QuestionCreate,
            edit: QuestionEdit,
            parentName: "hrasettings",
            options: {
              route: "question",
              label: "• Questions",
              collapsed: false,
            },
          },
          {
            name: "HRAAdmin",
            list: HRAAdmin,
            parentName: "hrasettings",
            options: {
              route: "hraadmin",
              label: "• Graph Settings",
              collapsed: false,
            },
          },
          {
            name: "Dimension",
            list: Dimension,
            create: DimensionCreate,
            edit: DimensionEdit,
            parentName: "hrasettings",
            options: {
              route: "dimension",
              label: "• Dimensions",
            },
          },
          {
            name: "Team",
            list: TeamList,
            create: TeamCreate,
            edit: TeamEdit,
            parentName: "metadata",
            options: {
              route: "team",
              label: "Team",
            },
          },
          {
            name: "Location",
            list: LocationList,
            create: LocationCreate,
            edit: LocationEdit,
            parentName: "metadata",
            options: {
              route: "location",
              label: "Location",
            },
          },
          {
            name: "JobTitle",
            list: JobTitleList,
            create: JobTitleCreate,
            edit: JobTitleEdit,
            parentName: "metadata",
            options: {
              route: "job-title",
              label: "Job Title",
            },
          },
          {
            name: "EmployeeType",
            list: EmployeeTypeList,
            create: EmployeeTypeCreate,
            edit: EmployeeTypeEdit,
            parentName: "metadata",
            options: {
              route: "employee-type",
              label: "Employee Type",
            },
          },
          {
            name: "forum",
            list: ActivityIndividual,
            options: {
              route: "admin/chat",
              label: "Chat",
            },
          },
          {
            name: "Category",
            list: CategoryList,
            create: CategoryCreate,
            edit: CategoryEdit,
            parentName: "forum",
            options: {
              route: "forum/category",
              label: "• Category",
            },
          },
          {
            name: "topic",
            list: TopicList,
            create: TopicCreate,
            edit: TopicEdit,
            parentName: "forum",
            options: {
              route: "forum/topic",
              label: "• Topic",
            },
          },
          {
            name: "post",
            list: PostList,
            create: PostCreate,
            edit: PostEdit,
            parentName: "forum",
            options: {
              route: "forum/post",
              label: "• Post",
            },
          },
          {
            name: "message",
            list: MessageList,
            parentName: "forum",
            options: {
              route: "forum/message",
              label: "• Message",
            },
          },
          {
            name: "metadata",
            list: ActivityIndividual,
            parentName: "system",
            options: {
              route: "admin/metadata",
              label: "• Metadata",
            },
          },
          {
            name: "system",
            list: HRAAdmin,
            // icon: <MessageOutlined />,
            options: {
              label: "System",
              disabled: true,
              route: "admin/system",
            },
          },
          {
            name: "white-email",
            list: WhiteEmailList,
            create: WhiteEmailCreate,
            edit: WhiteEmailEdit,
            parentName: "system",
            options: {
              route: "white-email",
              label: "• Whitelist",
            },
          },
        ]}
      />
    </ColorProvider>
  );
}

export default App;

import React from "react";
import { useList, useTable } from "@pankod/refine-core";
import { useMe } from "context/MeContext";
import { useCompany } from "context/CompanyContext";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { css } from "@emotion/react";

// const data = [
//   {
//     name: "Jan",
//     users: 24,
//   },
//   {
//     name: "Feb",
//     users: 100,
//   },
//   {
//     name: "Mar",
//     users: 400,
//   },
//   {
//     name: "Apr",
//     users: 800,
//   },
//   {
//     name: "May",
//     users: 1000,
//   },
//   {
//     name: "Jun",
//     users: 1250,
//   },
// ];
export default function TotalUsersPastSixmo() {
  const { company } = useMe();
  const { data: dataUsersPastSix } = useList({
    resource: "users-past-six",
    config: {
      sort: [
        {
          field: "id",
          order: "asc",
        },
      ],
      filters: [
        {
          field: "employee_code",
          operator: "eq",
          value: company?.code,
        },
      ],
    },
  });
  
  const Dim = dataUsersPastSix?.data
  //console.log({Dim})
  //const userAgeCount = dataUsersPastSix?.data;
  //console.log({userAgeCount})
  //const monthName = new Date("2024-05-12").toLocaleString('en-US', { month: 'long' });
  //const usersValue = dataUsersPastSix?.filter((data:any) => data?.day_created === '18-25')[0]?.users || 0;
  return (
    <LineChart width={500} height={300} data={Dim}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="month" padding={{ left: 30, right: 30 }} />
      <YAxis allowDecimals={false} domain={[0,"auto"]}/>
      <Tooltip />
      <Line
        type="monotone"
        dataKey="cum_users"
        stroke="#005E83"
        activeDot={{ r: 8 }}
      />
      {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
    </LineChart>
  );
}

import React from "react";
import { css } from "@emotion/react";
import { Button } from "antd";
import { NotificationType } from "types/notification";
import { useCompany } from "context/CompanyContext";
import { useNavigation, useUpdate } from "@pankod/refine-core";
import dayjs from "dayjs";

interface NotificationsProps {
  data?: any[];
  setCurrentPageNoti: React.Dispatch<React.SetStateAction<number>>;
  refetchTotalUnseenNotification: () => void;
  refetchTotalNotification: () => void;
  isLoading: boolean;
}
export const Notifications: React.FC<NotificationsProps> = ({
  data,
  refetchTotalUnseenNotification,
  setCurrentPageNoti,
  isLoading,
  refetchTotalNotification,
}) => {
  const { path } = useCompany();
  const onLoadMore = () => {
    setCurrentPageNoti((prev) => {
      return prev + 1;
    });
  };
  const { push } = useNavigation();
  const { mutateAsync: updateMessage } = useUpdate();

  const generateLink = (type: NotificationType) => {
    switch (type) {
      case NotificationType.ActivityReminderInApp:
        return `/${path}/activity`;
      case NotificationType.ChallengeReminderInApp:
        return `/${path}/challenge`;
      case NotificationType.HRAReminderInApp:
        return `/${path}/hra`;
      default:
        return ``;
    }
  };

  return (
    <div
      className={`flex flex-col bg-white rounded-md acc-setting acc-setting-responsive max-w-[300px] w-full max-h-[500px] h-full overflow-x-auto`}
      css={css`
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        .noti-single {
          padding: 10px 20px;
        }
        .line-thin {
          border-top: 1px solid rgba(128, 128, 128, 0.2);
          margin: 0;
          padding: 0;
        }
        .time {
          font-size: 11px;
          color: gray;
        }
      `}
    >
      {data?.map((item, index) => {
        const link = generateLink(item.type);
        return (
          <div key={item.id}>
            <div
              className={`noti-single cursor-pointer ${item.seenAt ? "" : "bg-slate-300"}`}
              onClick={async () => {
                await updateMessage({
                  resource: "notification",
                  values: {
                    seenAt: dayjs(),
                  },
                  id: item.id,
                  successNotification: false,
                  invalidates: ["list"],
                });
                refetchTotalUnseenNotification();
                refetchTotalNotification();
                link && push(link);
              }}
            >
              <div className="noti-text">{item.content}</div>
              <div className="time">{dayjs(item.createdAt).format("MM/DD/YYYY")}</div>
            </div>
            {index + 1 !== data.length && <div className="line-thin"></div>}
          </div>
        );
      })}
      <Button
        children="Load more"
        className="sticky bottom-0"
        onClick={onLoadMore}
        disabled={isLoading}
        loading={isLoading}
      />
    </div>
  );
};

import React, { useState } from "react";
import { css } from "@emotion/react";
import { Switch, Select, Form, Input } from "antd";
import { useCompany } from "context/CompanyContext";
import { useForm } from "@pankod/refine-antd";
import { FrequencyTime, NotificationInApp } from "types/user-settings";
import { useMe } from "context/MeContext";
import { useList } from "@pankod/refine-core";
import { LoadingFullPage } from "components/Loading/FullPage";
export const initialValues: {
  notification_in_app: NotificationInApp;
  notification_email: NotificationInApp;
} = {
  notification_in_app: {
    hra_reminder: {
      enabled: false,
      is_repeated: true,
      frequency: FrequencyTime.Monthly,
      description: "Reminds users to complete their health assessment for personalized insights",
      title: "HRA Reminder",
    },
    task_activity_alert: {
      enabled: false,
      is_repeated: true,
      frequency: FrequencyTime.Weekly,
      title: "Task & Activity Alert",
      description: "Alerts you about tasks and activities you need to complete",
    },
    challenge_reminder: {
      enabled: false,
      is_repeated: false,
      frequency: FrequencyTime.Weekly,
      title: "Challenge Reminder",
      description: "Reminds you to participate in challenges",
    },
    challenge_completion_congrats: {
      enabled: false,
      is_repeated: false,
      frequency: FrequencyTime.Weekly,
      title: "Challenge Completion Congrats",
      description: "Celebrates your success in completing a challenge",
    },
    post_moderation_notice: {
      enabled: false,
      is_repeated: false,
      frequency: FrequencyTime.Weekly,
      title: "Post Moderation Notice",
      description: "Informs users when their post or comment has been removed",
    },
  },
  notification_email: {
    login_reminder: {
      enabled: false,
      is_repeated: true,
      frequency: FrequencyTime.Weekly,
      title: "Login Reminder",
      description: "Reminds you to log in and stay active on the platform",
    },
    challenge_reminder: {
      enabled: false,
      is_repeated: false,
      frequency: FrequencyTime.Weekly,
      title: "Challenge Reminder",
      description: "Reminds you to participate in going challenges",
    },
    post_moderation_notice: {
      enabled: false,
      is_repeated: false,
      frequency: FrequencyTime.Weekly,
      title: "Post Moderation Notice",
      description: "Informs you when your post or comment has been removed",
    },
  },
};
const Notifications: React.FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const { hra_summary } = useCompany();
  const { me: user } = useMe();
  const { data, isLoading } = useList({
    resource: "user-setting",
    config: {
      filters: [
        {
          field: "user_id",
          operator: "eq",
          value: user?.id,
        },
      ],
    },
    queryOptions: {
      enabled: Boolean(user?.id),
    },
  });
  const userSetting = data?.data[0];
  const { formProps, formLoading } = useForm({
    resource: "user-setting",
    id: userSetting?.id,
    redirect: false,
    action: userSetting ? "edit" : "create",
    successNotification: false,
  });
  const onChange = () => {
    formProps.form?.submit();
  };
  const handleChange = () => {
    formProps.form?.submit();
  };
  const options = [
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "quarterly", label: "Quarterly" },
  ];

  if (isLoading) {
    return <LoadingFullPage />;
  }

  return (
    <div
      css={css`
        background-color: white;
        .ant-layout-content {
          background-color: red;
        }
        p {
          color: rgba(124, 124, 124, 0.7);
        }
        .noti-content-left {
          width: 30%;
        }
        .noti-content-right {
          width: 55%;
        }
        .switch {
          width: 11%;
          text-align: left;
          padding-top: 3px;
          @media (max-width: 578px) {
            padding-top: 0 !important;
          }
        }
        .text {
          text-align: left;
          width: 70%;
          @media (max-width: 578px) {
            width: 100%;
            padding-left: 10% !important;
            padding-top: 3px !important;
          }
          .noti {
            font-weight: 500;
            text-align: left;
          }
          p {
            text-align: left;
          }
        }
        .select {
          width: 20%;
          padding-top: 10px;
          @media (max-width: 578px) {
            padding-top: 0 !important;
          }
        }
        .ant-switch.ant-switch-checked {
          background-color: ${hra_summary?.main_hra_color} !important;
        }
        .hr {
          border-top: 1px solid rgba(124, 124, 124, 0.2);
          margin: 20px 0;
        }
        p {
          @media (max-width: 578px) {
            display: none !important;
          }
        }
        .noti-content {
          @media (max-width: 578px) {
            display: inline-block !important;
          }
        }
        .noti-content-right, .noti-content-left {
          @media (max-width: 578px) {
            width: 100% !important;
          }  
        }
        h1 {
          @media (max-width: 578px) {
            font-size: 22px;
          }  
        }
        h4 {
          @media (max-width: 578px) {
            margin-bottom: 20px;
            font-size: 16px;
            color: #6d7381;
          }  
        }
      `}
    >
      <h1>Notification settings</h1>
      <p>Select the kind of notifications you get about your activities and recommendations</p>
      <div className="hr"></div>
      <Form
        {...formProps}
        initialValues={userSetting || initialValues}
        onFinish={(values: any) => {
          console.log({ values });
          values.user_id = user?.id;
          formProps.onFinish?.(values);
        }}
      >
        {/* PUSH NOTIFICATION */}
        <div className="noti-content j-between">
          <div className="noti-content-left">
            <h4>Push notifications</h4>
            <p>Choose your push notifications that will appear when you log into GoodLife</p>
          </div>
          <div className="noti-content-right">
            {Object.entries(initialValues.notification_in_app).map((item) => {
              const details = item[1];
              return (
                <div className="content j-between" key={`notification_in_app${item[0]}`}>
                  <div className="switch">
                    <Form.Item name={["notification_in_app", item[0], "enabled"]}>
                      <Switch onChange={onChange} loading={formLoading} />
                    </Form.Item>
                  </div>
                  <div className="text">
                    <div className="noti">{details.title}</div>
                    <p css={css`
                      @media (max-width:768px) {
                        display: none;
                      }
                    `}>{details.description}</p>
                  </div>
                  <div className="select">
                    {details.is_repeated && (
                      <Form.Item name={["notification_in_app", item[0], "frequency"]}>
                        <Select
                          options={options}
                          style={{ width: isMobile ? "100px" : "150px" }}
                          onChange={handleChange}
                          loading={formLoading}
                        />
                      </Form.Item>
                    )}
                  </div>
                  <Form.Item name={["notification_in_app", item[0], "is_repeated"]} hidden>
                    <Switch />
                  </Form.Item>
                  <Form.Item name={["notification_in_app", item[0], "description"]} hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name={["notification_in_app", item[0], "title"]} hidden>
                    <Input />
                  </Form.Item>
                </div>
              );
            })}
          </div>
        </div>

        <div className="hr"></div>

        {/* EMAIL NOTIFICATION */}
        <div className="noti-content j-between">
          <div className="noti-content-left">
            <h4>Email notifications</h4>
            <p>Choose your email notifications that will be sent to the inbox of the email you use to login to GoodLife</p>
          </div>
          <div className="noti-content-right">
            {Object.entries(initialValues.notification_email).map((item) => {
              const details = item[1];
              return (
                <div className="content j-between" key={`notification_email${item[0]}`}>
                  <div className="switch">
                    <Form.Item name={["notification_email", item[0], "enabled"]}>
                      <Switch onChange={onChange} />
                    </Form.Item>
                  </div>
                  <div className="text">
                    <div className="noti">{details.title}</div>
                    <p>{details.description}</p>
                  </div>
                  <div className="select">
                    {details.is_repeated && (
                      <Form.Item name={["notification_email", item[0], "frequency"]}>
                        <Select options={options} 
                        style={{ width: isMobile ? "100px" : "150px" }} 
                        onChange={handleChange} />
                      </Form.Item>
                    )}
                  </div>
                  <Form.Item name={["notification_email", item[0], "is_repeated"]} hidden>
                    <Switch />
                  </Form.Item>
                  <Form.Item name={["notification_email", item[0], "description"]} hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name={["notification_email", item[0], "title"]} hidden>
                    <Input />
                  </Form.Item>
                </div>
              );
            })}
          </div>
        </div>
      </Form>
    </div>
  );
};
export default Notifications;
